import React, { useEffect, useState } from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';
import { useSelector } from 'react-redux';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//
const filterItemsByRole = (items, role)=>{
    let newItem = items.filter(item=>{
        let temp = item;
        if(temp.children && temp.children.length>0){
            temp.children = filterItemsByRole(temp.children, role);
        }
        if(!item.roles) return true;
        return (item.roles.includes(role));
    });
    return newItem;
}
const MenuList = () => {
    const account = useSelector((state)=>state.account);
    const [items, setItems] = useState([]);

    useEffect(() => {
         setItems(filterItemsByRole(menuItem.items, (account === null || account === undefined || account.user === null || account.user === undefined) ? "" : account.user.role));
     }, [setItems, account]);


    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
