let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  // BACKEND_SERVER = "https://thin-rat-85.telebit.io/api/v1/admin/";
  BACKEND_SERVER = "https://bbg.appdevelop.in/api/v1/admin/";
  // BACKEND_SERVER = "https://api.bbgplus.vip/api/v1/admin/";
}

const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    
    basename: '',
    adminDefaultPath: '/dashboard/default',
    defaultPath : '/dashboard/default',
    dispatcherDefaultPath: 'dispatch-staff/list',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 12,
    API_SERVER: BACKEND_SERVER
};

export default config;
