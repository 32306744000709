import React from 'react';
import PanelLogo from '../assets/images/logo/logo.png';

const Logo = () => {

    return (
        <img width={60} alt='Logo' src={PanelLogo}/>
    );
};

export default Logo;
